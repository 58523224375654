import React, {useEffect, useState} from "react";
import moment from "moment/moment";
import {classNames} from "../helper/ClassNames";

const MobileCalendar = ({selectedDateChanged}) => {

    const DATE_FORMAT = 'YYYY-MM-DD';
    const [selectedDate, setSelectedDate] = useState(moment());
    const [days, setDays] = useState([]);

    useEffect(() => {
        updateDays()
    },[selectedDate])

    function selectDate(date) {
        const d = moment(date, DATE_FORMAT);
        selectedDateChanged(d);
        setSelectedDate(moment(d, DATE_FORMAT));
    }
    function updateDays() {
        const newDays = [];
        const todayFormated = moment().format(DATE_FORMAT);
        const selectedDateFormated = moment(selectedDate).format(DATE_FORMAT);
        const first = moment(selectedDate).add(-4, 'd');
        for (let i= 0; i < 7; i++) {
            const current = first.add(1, 'd');
            const currentFormated = current.format(DATE_FORMAT);
            newDays.push({
                dayStr: current.format('dd'),
                date: currentFormated,
                day: current.format('D'),
                isToday: currentFormated === todayFormated,
                isSelected: currentFormated === selectedDateFormated
            })
        }
        setDays(newDays);
    }

    return (
        <div
            className="sticky top-0 z-10 grid flex-none grid-cols-7 bg-white text-xs text-gray-500 shadow ring-1 ring-black ring-opacity-5 md:hidden"
        >
            {days.map((day, dayIdx) => (
                <button key={dayIdx} type="button" onClick={() => selectDate(day.date)} className="flex flex-col items-center pb-1.5 pt-3">
                    <span>{day.dayStr}</span>
                    {/* Default: "text-gray-900", Selected: "bg-gray-900 text-white", Today (Not Selected): "text-indigo-600", Today (Selected): "bg-indigo-600 text-white" */}
                    <span className={classNames('mt-3 flex h-8 w-8 items-center justify-center rounded-full font-semibold text-base',
                        day.isSelected && day.isToday
                            ? 'bg-indigo-600 text-white'
                            : day.isSelected ? 'bg-gray-900 text-white' : day.isToday ? 'text-indigo-600' : 'text-gray-900' )}>
                        {day.day}
              </span>
                </button>
            ))}
        </div>
    )
}

export default MobileCalendar;
