import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import Nav from "./component/Nav";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import HomePage from "./page/HomePage";
import React from "react";
import {navigation} from "./data/Navigation";
import AppToastContainer from "./component/AppToastContainer";
import {configureMoment} from "./config/moment.config";


function App() {
    configureMoment()
    return (
      <BrowserRouter>
          <div className="h-full">
                    <AppToastContainer />
                    <Nav />
                <Routes>
                    <Route exact path="/" element={<HomePage />} />
                    {navigation.map((n, index) => <Route key={index} exact path={n.url} element={n.element} />)}
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
          </div>
      </BrowserRouter>
    );
}

export default App;
