import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useKeycloak} from "@react-keycloak/web";
import {format} from "../service/DateService";
import {toast} from "react-toastify";
import {
    ArrowLeftOnRectangleIcon,
    ArrowRightOnRectangleIcon
} from "@heroicons/react/20/solid";
import ConfirmBadgingModal from "../component/ConfirmBadgingModal";
import MobileCalendar from "../component/MobileCalendar";
import Calendar from "../component/Calendar";
import moment from "moment";



const BadgingPage = () => {

    function closeModal() {
        setModalOpen(false);
        setNextType('');
    }
    function sendBadging() {
        closeModal();
        setIsLoading(true);
        fetch(process.env.REACT_APP_SOKYUT_API_ROOT_URL + '/',{
            mode: "cors",
            method: "post",
            headers: {
                Authorization: "Bearer " + keycloak.token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({code: ""})
        })
            .then(response => {
                if (response.ok) {
                    return response.json()
                }
                return Promise.reject(response);
            })
            .then(() => toast.success("Enregistrement validé"))
            .then(() => refreshData())
            .catch((response) => {
                setIsLoading(false);
                if (response.json) {
                    response.json().then((err) => {
                        if (err.title && err.message && err.code) {
                            toast.error(err.message);
                        } else {
                            toast.error("Erreur inattendue");
                        }
                    }).catch(() => toast.error("Erreur inattendue"))
                } else toast.error("Erreur inattendue")
            });
    }

    function validateSent() {
        setIsLoading(true);
        fetch(process.env.REACT_APP_SOKYUT_API_ROOT_URL + '/next-type', {
            mode: "cors",
            method: "get",
            headers: {
                Authorization: "Bearer " + keycloak.token
            }
        }).then(response => {
            if (response.ok) {
                return response.json()
            }
            return Promise.reject(response);
        })
            .then(data => setNextType(data.type))
            .then(() => setIsLoading(false))
            .then(() => setModalOpen(true))
            .catch((response) => {
                setIsLoading(false);
                toast.error("Erreur inattendue");
            });
    }
    function refreshData() {
        setIsLoading(true);
        if (keycloak.hasRealmRole('SOKYUT_ADMIN')) {
            fetch(process.env.REACT_APP_SOKYUT_API_ROOT_URL + '/admin/by-day', {
                mode: "cors",
                method: "post",
                headers: {
                    Authorization: "Bearer " + keycloak.token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({date: moment(currentDate).toJsonWithOffset(), userId: userId})
            })
                .then(response => {
                    if (response.ok) {
                        return response.json()
                    }
                    return Promise.reject(response);
                })
                .then(data => setItems(data))
                .then(() => setIsLoading(false))
                .catch((response) => {
                    setIsLoading(false);
                    toast.error("Erreur inattendue");
                });
        } else {
            fetch(process.env.REACT_APP_SOKYUT_API_ROOT_URL + '/mine-by-day', {
                mode: "cors",
                method: "post",
                headers: {
                    Authorization: "Bearer " + keycloak.token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({date: moment(currentDate).toJsonWithOffset()})
            })
                .then(response => {
                    if (response.ok) {
                        return response.json()
                    }
                    return Promise.reject(response);
                })
                .then(data => setItems(data))
                .then(() => setIsLoading(false))
                .catch((response) => {
                    setIsLoading(false);
                    toast.error("Erreur inattendue");
                });
        }
    }

    function badgingTypeIcon(type) {
        if (type === 'IN') return <ArrowLeftOnRectangleIcon className="h-10 w-10 text-green-500" />;
        else if (type === 'OUT') return <ArrowRightOnRectangleIcon className="h-10 w-10 text-red-500" />;
        else return <></>;
    }

    const { keycloak, initialized } = useKeycloak();
    const [items, setItems] = useState([]);
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [nextType, setNextType] = useState('');
    const [userId, setUserId] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [currentDate, setCurrentDate] = useState(moment());
    useEffect(() => {
        if(keycloak && initialized && keycloak.authenticated) {
            refreshData();
            refreshUsers();
        }
    }, [keycloak,initialized, currentDate]);

    useEffect(() =>  {
        if(keycloak && initialized && keycloak.authenticated) {
            refreshData();
        }
    }, [userId]);


    function badgingTypeToText(type) {
        if (type === 'IN') return 'Entrée';
        if (type === 'OUT') return 'Sortie';
        return '';
    }

    function selectedDateChanged(selectedDate) {
        setCurrentDate(selectedDate);
    }

    function refreshUsers() {
        if (keycloak.hasRealmRole('SOKYUT_ADMIN')) {
            fetch(process.env.REACT_APP_SOKYUT_API_ROOT_URL + '/admin/users', {
                mode: "cors",
                method: "get",
                headers: {
                    Authorization: "Bearer " + keycloak.token,
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    if (response.ok) {
                        return response.json()
                    }
                    return Promise.reject(response);
                })
                .then(data => setUsers(data))
        }
    }


    return (
        <>
            <ConfirmBadgingModal
                open={modalOpen}
                badgingType={badgingTypeToText(nextType).toLowerCase()}
                closeModal={closeModal}
                sendBadging={sendBadging}
            />
        <div className="flex flex-col">
            <header className="flex flex-none items-center justify-between border-b border-gray-200 px-6 py-4">
                <div>
                    <h1 className="text-base font-semibold leading-6 text-gray-900">
                        <time className="sm:hidden">
                            {moment(currentDate).format('D MMMM YYYY')}
                        </time>
                        <time className="hidden sm:inline">
                            {moment(currentDate).format('D MMMM YYYY')}
                        </time>
                    </h1>
                    <p className="mt-1 text-sm text-gray-500">{moment(currentDate).format('dddd')}</p>

                    {keycloak.hasRealmRole('SOKYUT_ADMIN') && <div>
                        <div>
                            <select value={userId}
                                    onChange={e => {setUserId(e.target.value)}}
                                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            >
                                {users.map((user, index) => <option value={user.email}>{user.email}</option>)}
                            </select>
                        </div>
                    </div>}
                </div>
                <div className="flex items-center">
                    <button
                        type="button"
                        onClick={() => validateSent()}
                        className="ml-6 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-indigo-200 disabled:cursor-not-allowed"
                        disabled={moment(currentDate).format('YYYYMMDD') !== moment().format('YYYYMMDD')}>
                        Envoyer
                    </button>
                </div>
            </header>
            <div className="isolate flex flex-auto overflow-hidden bg-white">
                <div className="flex flex-auto flex-col overflow-auto">
                  <MobileCalendar selectedDateChanged={selectedDateChanged}/>
                        { items.length > 0 && <ul className="divide-y divide-gray-100 text-sm leading-6 lg:col-span-7 xl:col-span-8">
                            {
                                items.map((item, index) => <li key={index} className="relative flex space-x-6 p-2 xl:static">
                                    <div className="flex-none">
                                        {badgingTypeIcon(item.type)}
                                    </div>
                                    <div className="flex-auto">
                                        <h3 className="pr-10 font-semibold text-gray-900 xl:pr-0">
                                            {badgingTypeToText(item.type).toUpperCase()}
                                            {item.auto && <span className="ml-3 px-2 inline-flex flex-shrink-0 items-center rounded-full bg-blue-50 px-1.5 py-0.5 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-600/20">Auto</span>}
                                        </h3>
                                        <dl className="flex flex-col text-gray-500 xl:flex-row">{format(item.serverTime)}</dl>
                                    </div>

                                </li>)
                            }
                        </ul>
                        }
                </div>
                <div className="hidden w-1/2 max-w-md flex-none border-l border-gray-100 px-8 py-10 md:block">
                    <Calendar selectedDateChanged={selectedDateChanged}/>
                </div>
            </div>
        </div>
    </>
    )

};

export default BadgingPage;
