import React from 'react';
import {useKeycloak} from "@react-keycloak/web";



const HomePage = () => {

    const { keycloak, initialized } = useKeycloak();
    return (
        <main>

                <div className="text-center mt-20">
                    <h1 className="main-title">So Kyu't</h1>
                    { !keycloak.authenticated && <div>
                        <p>Vous n'êtes actuellement pas connecté</p>
                        <button onClick={() => keycloak.login()} className="mt-10 bg-indigo-600 hover:bg-indigo-500 text-white font-bold py-2 px-4 rounded-2xl">
                            Se connecter
                        </button>
                    </div>
                    }
                    { keycloak.authenticated && <div>
                        <p>Bienvenu sur l'application</p>
                    </div>
                    }
            </div>
        </main>)

};

export default HomePage;
