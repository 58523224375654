import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/20/solid";
import {classNames} from "../helper/ClassNames";
import React, {useEffect, useState} from "react";
import moment, {now} from "moment";

const Calendar = ({selectedDateChanged}) => {

    const DATE_FORMAT = 'YYYY-MM-DD';
    const [calendarDate, setCalendarDate] = useState(moment());
    const [selectedDate, setSelectedDate] = useState(moment());
    const [days, setDays] = useState([]);
    function previousMonth() {
        setCalendarDate(moment(calendarDate).add(-1, 'M'));
    }

    function nextMonth() {
        setCalendarDate(moment(calendarDate).add(1, 'M'));
    }

    useEffect(() => {
        updateDays()
    },[calendarDate, selectedDate])
    function updateDays() {
        const newDays = []
        const todayFormated = moment().format(DATE_FORMAT);
        const selectedDateFormated = moment(selectedDate).format(DATE_FORMAT);
        let before = moment(calendarDate).startOf('month').day();
        if (before < 3) before = before + 7;
        const first = moment(calendarDate).startOf('month').add(-(before), 'd');
        const currentMonth = moment(calendarDate).month();
        for (let i= 0; i < 42; i++) {
            const current = first.add(1, 'd');
            const currentFormated = current.format(DATE_FORMAT);
            newDays.push({
                    date: currentFormated,
                    isCurrentMonth: current.month() === currentMonth,
                    isToday: currentFormated === todayFormated,
                    isSelected: currentFormated === selectedDateFormated
                })
        }
        setDays(newDays);
    }

    function selectDate(date) {
        const d = moment(date, DATE_FORMAT);
        selectedDateChanged(d);
        setSelectedDate(moment(d, DATE_FORMAT));
    }

    function today(date) {
        setCalendarDate(moment().format(DATE_FORMAT))
        selectDate(moment().format(DATE_FORMAT))
    }

    return (<>
        <div className="flex items-center text-center text-gray-900">
            <button
                type="button"
                onClick={() => previousMonth()}
                className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
            >
                <span className="sr-only">Mois précédent</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <div className="flex-auto text-sm font-semibold capitalize">{moment(calendarDate).format('MMMM YYYY')}</div>
            <button
                type="button"
                onClick={() => nextMonth()}
                className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
            >
                <span className="sr-only">Mois suivant</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
        </div>
        <div className="mt-6 grid grid-cols-7 text-center text-xs leading-6 text-gray-500">
            <div>Lu</div>
            <div>Ma</div>
            <div>Me</div>
            <div>Je</div>
            <div>Ve</div>
            <div>Sa</div>
            <div>Di</div>
        </div>
        <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
            {days.map((day, dayIdx) => (
                <button
                    key={day.date}
                    onClick={() => selectDate(day.date)}
                    type="button"
                    className={classNames(
                        'py-1.5 hover:bg-gray-100 focus:z-10',
                        day.isCurrentMonth ? 'bg-white' : 'bg-gray-50',
                        (day.isSelected || day.isToday) && 'font-semibold',
                        day.isSelected && 'text-white',
                        !day.isSelected && day.isCurrentMonth && !day.isToday && 'text-gray-900',
                        !day.isSelected && !day.isCurrentMonth && !day.isToday && 'text-gray-400',
                        day.isToday && !day.isSelected && 'text-indigo-600',
                        dayIdx === 0 && 'rounded-tl-lg',
                        dayIdx === 6 && 'rounded-tr-lg',
                        dayIdx === days.length - 7 && 'rounded-bl-lg',
                        dayIdx === days.length - 1 && 'rounded-br-lg'
                    )}
                >
                    <time
                        dateTime={day.date}
                        className={classNames(
                            'mx-auto flex h-7 w-7 items-center justify-center rounded-full',
                            day.isSelected && day.isToday && 'bg-indigo-600',
                            day.isSelected && !day.isToday && 'bg-gray-900'
                        )}
                    >
                        {day.date.split('-').pop().replace(/^0/, '')}
                    </time>
                </button>
            ))}
        </div>
        <div className="mt-4 text-center">
            <button
                type="button"
                onClick={() => today()}
                className="ml-6 rounded-md bg-slate-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-slate-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600">
                Aujourd'hui
            </button>
        </div>
    </>)
}

export default Calendar;
