import React, {useEffect} from 'react';
import {useKeycloak} from "@react-keycloak/web";
import {Disclosure, Menu, Transition} from "@headlessui/react";
import {Bars3Icon, XMarkIcon, UserIcon, PowerIcon} from "@heroicons/react/20/solid";
import {classNames} from "../helper/ClassNames";
import {Link, useLocation} from "react-router-dom";
import {navigation} from "../data/Navigation";


const Nav = () => {
    const location = useLocation();
    useEffect(() => {
        locationChanged();
    }, [location]);

    function locationChanged() {
        navigation.forEach(n => n.current = n.url === location.pathname);
    }
    locationChanged();
    const { keycloak, initialized } = useKeycloak();
    return (<Disclosure as="nav" className="bg-gray-800">
            {({ open }) => (
                <>
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div className="flex h-16 items-center justify-between">
                            <div className="flex items-center">
                                <Link className="flex-shrink-0"
                                    to="/" >
                                    <img
                                        className="h-8 w-8"
                                        src="/img/logo.png"
                                        alt="Kyu Solutions"
                                    />
                                </Link>
                                <div className="hidden md:block">
                                    <div className="ml-10 flex items-baseline space-x-4">
                                        {keycloak.authenticated && navigation.map((item) => (
                                            <Link
                                                to={item.url}
                                                key={item.name}
                                                className={classNames(
                                                    item.current
                                                        ? 'bg-gray-900 text-white'
                                                        : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                    'rounded-md px-3 py-2 text-sm font-medium'
                                                )}
                                                aria-current={item.current ? 'page' : undefined}
                                            >
                                                {item.name}
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            {!keycloak.authenticated && <div className="hidden md:block">
                                <Menu as="div" className="relative ml-3">
                                    <div>
                                        <Menu.Button onClick={() => keycloak.login()} className="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                            <span className="absolute -inset-1.5" />
                                            <span className="sr-only">Connexion</span>
                                            <PowerIcon className="h-6 w-6" aria-hidden="true" />
                                        </Menu.Button>
                                    </div>
                                </Menu>
                                </div>
                            }
                            {keycloak.authenticated && <div className="hidden md:block">
                                    <div className="ml-4 flex items-center md:ml-6">
                                        <span className="relative rounded-full bg-gray-800 p-1 text-gray-400 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                            {keycloak.tokenParsed.name}
                                        </span>
                                        <Menu as="div" className="relative ml-3">
                                            <div>
                                                <Menu.Button className="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm text-white outline-none ring-2 ring-white ring-offset-2 ring-offset-gray-800">
                                                    <span className="absolute -inset-1.5" />
                                                    <span className="sr-only">Open user menu</span>
                                                    <UserIcon className="h-6 w-6" aria-hidden="true" />
                                                </Menu.Button>
                                            </div>
                                            <Transition
                                                as={React.Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                        <Menu.Item key="logout">
                                                                <a onClick={() => keycloak.logout()}
                                                                    className="block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100">
                                                                    Deconnexion
                                                                </a>
                                                        </Menu.Item>
                                                </Menu.Items>
                                            </Transition>
                                        </Menu>
                                    </div>
                                </div>
                            }
                            <div className="-mr-2 flex md:hidden">
                                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                    <span className="absolute -inset-0.5" />
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                    ) : (
                                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                    )}
                                </Disclosure.Button>
                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className="md:hidden">
                        <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                            {keycloak.authenticated && navigation.map((item) => (
                                <Link
                                    key={item.name}
                                    to={item.url}
                                    className={classNames(
                                        item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                        'block rounded-md px-3 py-2 text-base font-medium'
                                    )}
                                    aria-current={item.current ? 'page' : undefined}
                                >
                                    {item.name}
                                </Link>
                            ))}
                        </div>

                        <div className="border-t border-gray-700 pb-3 pt-4">
                            { keycloak.authenticated && <>
                                <div className="flex items-center px-5">
                                    <div className="flex-shrink-0">
                                        <UserIcon />
                                    </div>
                                    <div className="ml-3">
                                        <div className="text-base font-medium text-white">{keycloak.tokenParsed.name}</div>
                                        <div className="text-sm font-medium text-gray-400">{keycloak.tokenParsed.email}</div>
                                    </div>
                                </div>
                                <div className="mt-3 space-y-1 px-2">
                                        <Disclosure.Button onClick={() => keycloak.logout()}
                                            key="logout"
                                            as="a"
                                            className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                                        >
                                            Deconnexion
                                        </Disclosure.Button>
                                </div>
                            </>
                            }
                            { !keycloak.authenticated && <div className="mt-3 space-y-1 px-2">
                                    <Disclosure.Button
                                        key="login"
                                        as="a"
                                        onClick={keycloak.login}
                                        className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                                    >
                                        Connexion
                                    </Disclosure.Button>
                            </div>
                            }
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>);
};

export default Nav;
